<template>

    <b-card-code
      :title='$t(`message.sales`)'
      no-body
    >

    <b-modal id="modal-ferror" title="Something went wrong" v-model="modalAction.show" ok-only centered @hide="fetchErr(modalAction.redirect)">
        <p class="my-4">{{$t(`message.erroroccured`)}}</p>
    </b-modal>
    
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
  
          <!-- sorting  -->
          <b-form-group
            label="Sort"
            label-size="sm"
            label-align-sm="left"
            label-cols-sm="2"
            label-for="sortBySelect"
            class="mr-1 mb-md-0 align-items-center"
          >
            <b-input-group
              size="sm"
            >
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
              >
                <template #first>
                  <option value="">
                    none
                  </option>
                </template>
              </b-form-select>
              <b-form-select
                v-model="sortDesc"
                size="sm"
                :disabled="!sortBy"
              >
                <option :value="false">
                  Asc
                </option>
                <option :value="true">
                  Desc
                </option>
              </b-form-select>
            </b-input-group>
          </b-form-group>
  
          <!-- filter -->
          <b-form-group
            label="Filter"
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0 align-items-center"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Search"
                debounce=500
              />
              <b-input-group-append>
                <b-button
                  :disabled="!filter"
                  @click="filter = ''"
                >
                  Clear
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-card-body>
  
  
    <b-card-code
      title=""
      no-body
    >
   
      <b-table
        ref="selectableTable"
        striped
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        :busy="isBusy"
        >
        <template #table-busy>
            <div class="d-flex align-items-center justify-content-around my-2">
            <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
            <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
            <b-spinner variant="dark" style="width: 3rem; height: 3rem;"></b-spinner>
            </div>
        </template>
        <!-- state -->
        <template #cell(state)="data">
            <b-dropdown v-if="ls_role=='admin'" :variant='`${data.item.state=="issued" ? "outline-success" : "outline-danger"}`' size="sm" :id="'dropdown-st'+data.item.transaction_id" :text="data.item.state" class="m-md-2">
              <b-dropdown-item variant="success" @click="updateState(data.item.transaction_id,'issued')">issued</b-dropdown-item>
              <b-dropdown-item variant="danger" @click="updateState(data.item.transaction_id,'cancelled')">cancelled</b-dropdown-item>
          </b-dropdown>
          <b-badge v-else pill :class="data.item.state">
                {{ data.item.state }} 
            </b-badge>
        </template>
        <!-- customer_name -->
        <template #cell(customer_name)="data">
            <b-dropdown block variant="outline-success" size="sm" :id="'dropdown-csn'+data.item.transaction_id" :text="data.item.customer_name" class="m-md-2" @show="sendInfo(data.item.transaction_id, 'information')">
              <b-dropdown-item variant="success" v-if="!customer_link">{{ loadingDrop }}</b-dropdown-item>
              <b-dropdown-item variant="success" v-else target="_blank" :href="customer_link">{{ data.item.customer_name }} Info</b-dropdown-item>
          </b-dropdown>
        </template>
        <!-- summary -->
        <template #cell(summary)="data">
            <b-dropdown block variant="outline-secondary" size="sm" :id="'dropdown-dn'+data.item.transaction_id" text="Debit Note" class="m-md-2" @show="sendInfo(data.item.transaction_id, 'summary')">
              <b-dropdown-item variant="secondary" v-if="!debit_link">{{ loadingDrop }}</b-dropdown-item>
              <b-dropdown-item variant="secondary" v-else target="_blank" :href="debit_link">Summary</b-dropdown-item>
          </b-dropdown>
        </template>
        <!-- customers -->
        <template #cell(insurance_evidence)="data">
            <b-dropdown size="sm" :id="'dropdown-ins'+data.item.quote_id" text="Download" class="m-md-2" @show="getCustomers(data.item.transaction_id, data.item.policyType, data.item.summary)" boundary="viewport">
                <b-dropdown-item v-if="!customersArr.length">{{ loadingDrop }}</b-dropdown-item>
                <b-dropdown-item v-else target="_blank" :href="policyLink">Insurance Evidence</b-dropdown-item>
                <b-dropdown-item v-for="(item, index) in customersArr" :key="index" target="_blank" :href="policyLink+'&tr='+index">{{ item }}</b-dropdown-item>
            </b-dropdown>
        </template>
        <!-- validation certificate -->
        <template #cell(validation_certificate)="data">
            <b-button v-if="checkValidation(data.item.policyType)" @click="validCert(data.item.policyType, data.item.summary)" size="sm" variant="outline-secondary">Download</b-button>
            <p v-else class="text-center">&#9473;</p>
        </template>
        <!-- master policy -->
        <template #cell(master_policy)="data">
            <b-button v-if="checkPolicy(data.item.policyType)" @click="masterPolicies(data.item.policyType)" size="sm" variant="outline-secondary"><font-awesome-icon :icon="['fas', 'download']" /></b-button>
            <p v-else class="text-center">&#9473;</p>
        </template>
        <!-- tob -->
        <template #cell(tob)="data">
            <b-button v-if="checkTobs(data.item.policyType)" @click="tobs(data.item.policyType)" size="sm" variant="outline-secondary"><font-awesome-icon :icon="['fas', 'download']" /></b-button>
            <p v-else class="text-center">&#9473;</p>
        </template>
        <!-- ipid -->
        <template #cell(ipid)="data">
            <b-button v-if="checkIpids(data.item.policyType)" @click="ipids(data.item.policyType)" size="sm" variant="outline-secondary"><font-awesome-icon :icon="['fas', 'download']" /></b-button>
            <p v-else class="text-center">&#9473;</p>
        </template>
        <!-- email -->
        <template #cell(email_schedule)="data">
            <font-awesome-icon icon="fas fa-envelope" @click="sendEmail(data.item.policyType, data.item.summary)" style="cursor:pointer;"/>
        </template>
      </b-table>
  
      <!-- buttons -->
      <b-card-body class="demo-inline-spacing pt-0" v-if="items.length">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="downloadExcel(items,'Sales.xlsx')"
        >
          Sales Excel
        </b-button>
      </b-card-body>
  
      <template #code>
        {{ codeRowSelectSupport }}
      </template>
    </b-card-code>
  
      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
  
        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1 align-items-center"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>
  
        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0 align-items-center"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
  
      <template #code>
        {{ codeKitchenSink }}
      </template>
    </b-card-code>
  </template>
  
  <script>
  import { cookie, token_id, product_documents, ls_role, ls_email, downloadExcelMixin } from '@/products';
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import {
    BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody, BDropdown, BDropdownItem, BAlert, BSpinner
  } from 'bootstrap-vue'
  import { codeKitchenSink } from '../table/bs-table/code'
  import vSelect from 'vue-select'
  import Ripple from 'vue-ripple-directive'
  import { codeRowSelectSupport } from '../table/bs-table/code'
  
  export default {
    mixins: [downloadExcelMixin],
    components: {
      BCardCode,
      BTable,
      BAvatar,
      BBadge,
      BFormGroup,
      BFormSelect,
      BPagination,
      BInputGroup,
      BFormInput,
      BInputGroupAppend,
      BButton,
      BCardBody,
      BDropdown,
      BDropdownItem,
      BAlert,
      vSelect,
      BSpinner
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        customer_link: null,
        debit_link: null,
        processing: false,
        loadingDrop: 'Loading...',
        modalAction: {
            show: false,
            redirect: false,
        },
        isBusy: true,
        policyLink:'',
        masterPolicy:null,
        validCerf:null,
        tobLink:null,
        ipidLink:null,
        customersArr: [],
        modes: ['multi', 'single', 'range'],
        perPage: 5,
        pageOptions: [5, 10, 25, 50, 100],
        totalRows: 1,
        currentPage: 1,
        sortBy: 'r_timestamp',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'quote_id', label: 'ID', sortable: true },
          { key: 'transaction_id', label: 'Transaction ID', sortable: true },
          { key: 'state', label: 'State', sortable: true },
          { key: 'number_of_customers', label: 'No. of Insured', sortable: true },
          { key: 'customer_name', label: 'Persons', sortable: true },
          { key: 'email', label: 'Email', sortable: true },
          { key: 'on_behalf_email', label: 'Behalf Of', sortable: true },
          { key: 'residence_literal', label: 'Residence', sortable: true, tdClass: 'text-nowrap' },
          { key: 'destination_literal', label: 'Destination', sortable: true, tdClass: 'text-nowrap' },
          { key: 'zone', label: 'Zone', sortable: true, tdClass: 'text-nowrap' },
          { key: 'start_date', label: 'Start Date', sortable: true },
          { key: 'end_date', label: 'End Date', sortable: true },
          { key: 'product_name', label: 'Product', sortable: true, tdClass: 'text-nowrap' },
          { key: 'full_amount', label: 'Total Amount', sortable: true },
          { key: 'clean_amount', label: 'Clean Amount', sortable: true },
          { key: 'consent', label: 'Consent', sortable: true },        
          { key: 'summary', label: 'Summary' },
          { key: 'insurance_evidence', label: 'Insurance Evidence' },
          { key: 'validation_certificate', label: 'Validation Certificate' },
          { key: 'master_policy', label: 'Master Policy' },
          { key: 'tob', label: 'TOB' },
          { key: 'ipid', label: 'IPID'},
          { key: 'email_schedule', label: 'Email Schedule' },
          { key: 'r_timestamp', label: 'Date of Issue', sortable: true, tdClass: 'text-nowrap' },
        ],
        /* eslint-disable global-require */
        items: [],
        /* eslint-disable global-require */
        status: [{
          1: 'Current', 2: 'Professional', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
        },
        {
          1: 'light-primary', 2: 'light-success', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
        }],
        codeKitchenSink,
        selected: [],
        codeRowSelectSupport,
        dismissSecs: 5,
        dismissCountDown: 5,
        showDismissibleAlert: false,
        invoiceDropState: false,
        download_link: null,
        issue_pol: null,
        email_schedule: null,
        cookie,
        token_id,
        ls_role,
        ls_email,
        product_documents
      }
    },
    computed: {
      sortOptions() {
        // Create an options list from our fields
        return this.fields
          .filter(f => f.sortable)
          .map(f => ({ text: f.label, value: f.key }))
      },
    },
    async created() {
      // Fetch the first batch and display results
      await this.fetchTable(0, 50, true);
      // Define the ranges for subsequent fetches
      const ranges = [
          [50, 200],
          [200, 500],
          [500, 1000],
          [1500, 2000]
      ];
      // Fetch remaining batches sequentially using promise chaining
      for (const [start, end] of ranges) {
          await this.fetchTable(start, end, false);
      }
    },
    methods: {
      checkValidation(product) {
        if (['product-single-900','product-single-901','product-single-902','product-annual-901','product-annual-902','product-longstay-901','product-longstay-902','product-single-903','product-single-904','product-single-300','product-single-301','product-single-302','product-single-303','product-single-304','product-single-305','product-single-306','product-single-307','product-annual-301','product-annual-302','product-longstay-301','product-single-908','product-single-909','product-longstay-302',].includes(product)) {
          return true
        }
        return false
      },
      checkIpids(product) {
        const num = product.replace(/^\D+/g, '');
        if (num[0] == '8') {
            return false
        } else if (num[0] == '4') {
            if (['product-single-408','product-single-409','product-single-410'].includes(product)) {
              return false
            }
        }
        return true
      },
      checkTobs(product) {
        const num = product.replace(/^\D+/g, '');
        if (num[0] == '8' || num[0] == '4') {
            return false
        } 
        return true
      },
      checkPolicy(product) {
        const num = product.replace(/^\D+/g, '');
        if (num[0] == '6') {
            return false
        } 
        return true
      },
      async fetchTable(start_limit, end_limit, init) {
        const url= {
          prod: 'https://api-quote.safetravel.today/v2/app/sales'
        };
        const data = {
          token: this.token_id,
          jwt: this.cookie,
          start_limit: start_limit,
          end_limit: end_limit,
        }
        try {
          await fetch(url.prod, {
              method: 'post',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(data)
          })
          .then((response)=>response.json())
          .then((res)=>{
            if (res === 'not_valid') {
              window.location.href = '/logout';
              return;
            }
            if (res) {
              res.forEach((r)=> {
                if (!this.items.some(item => item.transaction_id === r.transaction_id)) {
                  this.items.push({
                    quote_id: r?.quote_id,
                    transaction_id: r?.transaction_id,
                    state: r?.state,
                    number_of_customers: r?.number_of_customers,
                    customer_name: r?.customer_name,
                    residence_literal: r?.residence_literal,
                    destination_literal: r?.destination_literal,
                    email: r?.email,
                    on_behalf_email: r?.on_behalf_email,
                    zone: r?.zone,
                    start_date: r?.start_date,
                    end_date: r?.end_date,
                    product_name: r?.product_name,
                    full_amount: r?.full_amount,
                    clean_amount: r?.clean_amount,
                    consent: r?.consent==0 ? 'No' : 'Yes',
                    summary: r?.transaction_token,
                    insurance_evidence: '',
                    validation_certificate: '',
                    master_policy: '',
                    ipid: '',
                    tob: '',
                    email_schedule: r?.email_schedule,
                    r_timestamp: r?.r_timestamp,
                    policyType: r?.policyType,
                    on_behalf_of: r?.on_behalf_of
                  })
                }
              });
            }
          })
          .then(() => {
            this.totalRows = this.items.length;
            this.isBusy = false;
            this.sortBy = 'r_timestamp';
          })
        } catch (error) {
          // TypeError: Failed to fetch
          console.warn('There was an error', error);
          if (init) {
            this.modalAction = {
                show: true,
                redirect: false,
            }
          }
        }
      },
      fetchErr(redirect) {
        if (redirect) {
            window.location.href = '/landing';
        }
      },
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      showAlert() {
        this.dismissCountDown = this.dismissSecs
      },
      info(item, index, button) {
        this.infoModal.title = `Row index: ${index}`
        this.infoModal.content = JSON.stringify(item, null, 2)
        this.$root.$emit('bv::show::modal', this.infoModal.id, button)
      },
      resetInfoModal() {
        this.infoModal.title = ''
        this.infoModal.content = ''
      },
      onFiltered(filteredItems) {
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },
      async getCustomers(tr_id, policyType, tr_token) {
        this.customersArr=[];
        const url= {
            prod: 'https://api-quote.safetravel.today/v2/app/customer'
        };
        const data = {
            token: this.token_id,
            transactionID: tr_id,
            jwt: this.cookie
        }
        try {
            this.loadingDrop='Loading...';
            await fetch(url.prod, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            })
            .then((response)=>response.json())
            .then((response)=> {
              if (response === 'not_valid') {
                window.location.href = '/logout';
                return;
              }
              if (response) {
                response.forEach(element => {
                    this.customersArr.push(element.customer_name);
                    this.customersArr= [...new Set(this.customersArr)];
                });
              }
            })
            .then(()=> {
            this.policyLinks(policyType, tr_token);
            })
        } catch (error) {
            // TypeError: Failed to fetch
            console.warn('There was an error fetching customers', error);
            this.loadingDrop='Error...';
        }
      },
      policyLinks(policyType, tr_token) {
        this.policyLink='';
        const num = policyType.replace(/^\D+/g, '');
        if (num[0] == '9') {
            this.policyLink = 'https://quote.safetravel.today/insurance_certificate.php?token='+tr_token;
        } else if (num[0] == '6') {
            this.policyLink = 'https://quote.safetravel.today/insurance_certificate_m.php?token='+tr_token;
        } else if (num[0] == '8') {
            this.policyLink = 'https://quote.safetravel.today/insurance_certificate_k.php?token='+tr_token;
        } else if (num[0] == '3') {
            this.policyLink = 'https://quote.safetravel.today/validation_certificate_s.php?token='+tr_token;
        } else if (num[0] == '4') {
            if (['product-single-401','product-single-402','product-single-403','product-single-404','product-single-405','product-single-406','product-single-407'].includes(policyType)) {
                this.policyLink ='https://quote.safetravel.today/insurance_evidence_b.php?token='+tr_token;
            } else if (['product-single-408','product-single-409','product-single-410'].includes(policyType)) {
                this.policyLink ='https://quote.safetravel.today/insurance_evidence_crum.php?token='+tr_token;
            }
        }
      },
      validCert(product, tr_token) {
        const hannoverArr = ['product-single-900', 'product-single-901', 'product-single-902','product-annual-901', 'product-annual-902', 'product-longstay-901','product-longstay-902', 'product-single-903', 'product-single-904','product-single-908','product-single-909'];
        const sompoArr = ['product-single-300', 'product-single-301', 'product-single-302','product-single-303', 'product-single-304', 'product-single-305', 'product-single-306', 'product-single-307', 'product-annual-301','product-annual-302', 'product-longstay-301', 'product-longstay-302'];
        // Set validCerf based on product
        this.validCerf = hannoverArr.includes(product)
          ? 'https://quote.safetravel.today/validation_certificate.php?token=' + tr_token
          : sompoArr.includes(product)
          ? 'https://quote.safetravel.today/validation_certificate_s.php?token=' + tr_token
          : null;
        // Open the URL if validCerf is set
        if (this.validCerf) {
          window.open(this.validCerf);
        }
      },
      masterPolicies(product_code) {
        this.masterPolicy= this.product_documents[product_code]?.pwo_link;
        // open master policy
        if (this.masterPolicy) {
            window.open(this.masterPolicy);
        }
      },
      tobs(product_code) {
        this.tobLink=this.product_documents[product_code]?.tob_link;
        // open tob 
        if (this.tobLink) {
            window.open(this.tobLink);
        }
      },
      ipids(product_code) {
        this.ipidLink = this.product_documents[product_code]?.ipid_link;
        // open ipid 
        if (this.ipidLink) {
            window.open(this.ipidLink);
        }
      },
      sendEmail(policyType, tr_token) {
        this.policyLinks(policyType, tr_token);
        if (this.policyLink) {
            window.location.href = `mailto:${this.ls_email}?subject=Insurance Evidence&body=Here is the link to the insurance evidence: ${this.policyLink}`;
        }
      },
      updateState(transactionID,state) {
        const url= {
                prod: 'https://api-quote.safetravel.today/v2/app/change_state'
            };
            const data = {
                token: this.token_id,
                jwt: this.cookie,
                transactionID,
                state: state.toLowerCase()
            }
            try {
                fetch(url.prod, {
                    method: 'post',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(data)
                })
                .then((response)=>response.json())
                .then((res)=> {
                    if (res) {
                        const btn = "#dropdown-st"+transactionID+" > button";
                        const btncl = document.querySelector(btn).classList;
                        document.querySelector(btn).textContent=state;
                        if (state=='issued') {
                          btncl.remove("btn-outline-danger");
                          btncl.add("btn-outline-success");
                        } else {
                          btncl.remove("btn-outline-success");
                          btncl.add("btn-outline-danger");
                        }
                    } else {
                        alert('There was an error changing state');
                    }
                })
            } catch (error) {
                // TypeError: Failed to fetch
                console.warn('There was an error changing state', error);
            }
      },
      async sendInfo(policyId, pageLink) {
        this.customer_link=null;
        this.debit_link=null;
        // if a request is processing
        if (this.processing === true) {
          return;
        } 
        this.processing = true;

        const url= {
            prod: 'https://api-quote.safetravel.today/v2/app/get_tr_tokenized'
        };
        const data = {
            token: this.token_id,
            policyID: policyId,
            jwt: this.cookie
        };
        try {
          await fetch(url.prod, {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            })
            .then((response)=>response.json())
            .then((res)=> {
              if (res === 'not_valid') {
                window.location.href = '/logout';
                return;
              }
              if (pageLink == 'information') {
                this.customer_link = pageLink+"?t="+res
              } else if (pageLink == 'summary') {
                this.debit_link = pageLink+"?t="+res
              } else {
                this.debit_link = null;
              }
            })
        } catch (error) {
          // TypeError: Failed to fetch
          console.warn('There was an error to get policy Id', error);
        }
        finally {
          this.processing = false;
        }
      },
    },
  }
  </script>
  <style scoped>
    ::v-deep .dropdown-menu {
      max-height: 250px;
      overflow-y: auto;
    }
    span.cancelled {
        background-color: #dc3545;
    }
    span.issued {
        background-color: #4abb9d;
    }
  </style>
  